.title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
}
.content {
    background-image: linear-gradient(to bottom left, #0FC5FF 30%, #FF0099 90%);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 70vh;
    padding-top: 86px;
}

.titleMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}
.contentMobile {
    background-image: linear-gradient(to bottom left, #0FC5FF 30%, #FF0099 90%);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    padding-top: 86px;
}



