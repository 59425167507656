.main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.content {
    background-color: #1A1C43;
    color: white;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;
    margin-top: -8%;
}

.mainMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.contentMobile {
    background-color: #1A1C43;
    color: white;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
    margin-top: -15%;
}
